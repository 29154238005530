import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaFileAlt } from "@react-icons/all-files/fa/FaFileAlt";

const ProjectSelectionAnimation = () => {
  return (
    <div className="w-full relative">
      <div className="absolute top-0 left-0 bg-white px-3 py-3 shadow-3xl w-72 sm:w-80 lg:w-96 rounded-md z-10">
        <div className="py-2">
          <p className="text-sm text-gray-700 font-regular">
            <FormattedMessage id="progress_selection_title" />
          </p>
          <p className="text-xs text-gray-500 font-light py-2">
            <FormattedMessage id="progress_selection_subtitle" />
          </p>
        </div>
        <div className="w-full h-full bg-[#222222] rounded-md ring-2 ring-[#222222] relative">
          <StaticImage
            src={"../../images/animations/dk-5.png"}
            placeholder="blurred"
            layout="constrained"
            loading="lazy"
            alt="map of Denmark"
            className="z-10"
          />
          <div className="bg-blueish-400 absolute top-[44%] left-[20%] w-3 h-3 rounded-full z-20 animate-ping opacity-75"></div>
          <div className="bg-blueish-400 absolute top-[44%] left-[20%] w-3 h-3 rounded-full z-20"></div>
          <div className="bg-white absolute top-[65%] left-[70%] w-2 h-2 rounded-full z-20 opacity-60"></div>
          <div className="bg-white absolute top-[60%] left-[65%] w-2 h-2 rounded-full z-20 opacity-60"></div>
          <div className="bg-white absolute top-[60%] left-[30%] w-2 h-2 rounded-full z-20 opacity-60"></div>
          <div className="bg-white absolute top-[35%] left-[26%] w-2 h-2 rounded-full z-20 opacity-60"></div>
          <div className="bg-white absolute top-[29%] left-[32%] w-2 h-2 rounded-full z-20 opacity-60"></div>
          <div className="bg-white absolute top-[45%] left-[40%] w-2 h-2 rounded-full z-20 opacity-60"></div>
          <div className="bg-white absolute top-[70%] left-[50%] w-2 h-2 rounded-full z-20 opacity-60"></div>
        </div>
      </div>
      <div className="absolute right-0 lg:-right-16 w-52 lg:w-60 z-30 -top-6">
        <div className="grid grid-cols-4 h-12 md:h-16 w-full items-center rounded-md px-0 sm:px-2 bg-white shadow-3xl">
          <div className="col-span-1 text-lg pl-4 text-blueish-400">
            <FaEnvelope />
          </div>
          <div className="col-span-3 text-xs font-light text-gray-500">
            <p className="font-medium">
              <FormattedMessage id="progress_selection_time" />
            </p>
            <p>
              <FormattedMessage id="progress_selection_received" />
            </p>
          </div>
        </div>
      </div>
      <div className="absolute top-6 -right-0 lg:-right-16 w-52 lg:w-60 z-20 top-16">
        <div className="w-full bg-white shadow-3xl rounded-md flex flex-col">
          <div className="w-full rounded-tl-md rounded-tr-md h-28 bg-white border-b border-gray-200">
            <div className="w-full h-full flex flex-col justify-center items-center">
              <div className="h-12 w-12 bg-gray-100 rounded-md flex justify-center items-center text-lg text-gray-700">
                <FaFileAlt />
              </div>
              <p className="w-full text-center text-gray-700 font-light text-sm pt-2">
                <FormattedMessage id="progress_selection_proposal" />
              </p>
            </div>
          </div>
          <div className="h-44 w-full flex flex-col px-2 lg:px-4 py-4 ">
            <p className="text-xs font-light text-gray-500 w-full text-center">
              <FormattedMessage id="progress_selection_prepared" />
            </p>
            <div className="w-full grid grid-cols-5 text-xs text-gray-500 mt-7 px-2">
              <div className="border-r border-gray-200 ">
                <p className="font-medium">
                  <FormattedMessage id="progress_selection_type_title" />
                </p>
                <p className="font-light mt-2">
                  <FormattedMessage id="progress_selection_type_text" />
                </p>
              </div>
              <div className="border-r border-gray-200 pl-3 col-span-2">
                <p className="font-medium">
                  <FormattedMessage id="progress_selection_location_title" />
                </p>
                <p className="font-light mt-2">
                  <FormattedMessage id="progress_selection_location_text" />
                </p>
              </div>
              <div className="pl-3 col-span-2">
                <p className="font-medium">
                  <FormattedMessage id="progress_selection_operational_title" />
                </p>
                <p className="font-light mt-2">
                  <FormattedMessage id="progress_selection_operational_text" />
                </p>
              </div>
            </div>
            <div className="h-10 w-full text-center text-xs px-2 py-2 bg-blueish-400 text-white rounded-md mt-7">
              <FormattedMessage id="progress_selection_project_details" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSelectionAnimation;
