import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import { FaPlug } from "@react-icons/all-files/fa/FaPlug";
import { FaSun } from "@react-icons/all-files/fa/FaSun";
import { FaLongArrowAltDown } from "@react-icons/all-files/fa/FaLongArrowAltDown";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";

const PoweredByRenewables = () => {
  return (
    <div className="h-[420px] w-[280px] sm:w-[370px] sm:h-[500px]">
      <div className="shadow-3xl bg-white rounded-md h-full overflow-hidden relative ">
        <div className="py-4 px-4">
          <p className="text-sm text-gray-700 font-regular">
            <FormattedMessage id="powered_by_animation_title" />
          </p>
        </div>
        <div className="w-full flex flex-col opacity-0 absolute top-8 horisontal-carousel-step-1 animation-trigger-2 px-4 py-4">
          <div className="mt-2 sm:mt-4 flex justify-between">
            <p className="text-xs text-gray-500">
              <FormattedMessage id="powered_by_animation_live_consumption" />
            </p>
            <div className="pl-2 pr-1 py-1 ring-1 ring-gray-100 text-xs rounded-md grid grid-cols-4 gap-x-1">
              <p className="text-blueish-400">1d</p> <p>1w</p> <p>1m</p>{" "}
              <p>1y</p>
            </div>
          </div>
          <div className="w-full mt-4 pt-4 sm:pt-8 pb-2 sm:pb-4 px-2 rounded-md bg-white ring-1 ring-gray-100">
            <StaticImage
              src={"../../images/animations/graph_cons.png"}
              placeholder="blurred"
              layout="constrained"
              loading="lazy"
              alt="graph of consumption data"
            />
          </div>
          <div className="mt-4 sm:mt-8 flex justify-between">
            <p className="text-xs text-gray-500">
              <FormattedMessage id="powered_by_animation_period" />
            </p>
            <p className="text-xs text-gray-500 font-light">
              <FormattedMessage id="powered_by_animation_options" />{" "}
              <FaChevronDown className="inline text-[10px]" />
            </p>
          </div>
          <div className="w-full mt-4 grid grid-cols-2 gap-x-4">
            <div className="w-full flex flex-col px-2 sm:px-4 py-4 rounded-md bg-white ring-1 ring-gray-100">
              <div className="text-sm text-gray-500">
                <FaPlug />
              </div>
              <p className="text-[11px] text-gray-500 font-light mt-4">
                <FormattedMessage id="powered_by_animation_total_consumption" />
              </p>
              <p className="text-xs text-gray-500 font-medium mt-4">
                2.4 GWh{" "}
                <span className="bg-[#F7E7BA] text-[#A6681C] rounded-md px-[1px] py-[1px] ml-2 font-regular">
                  +1.3%
                </span>
              </p>
            </div>
            <div className="w-full flex flex-col px-2 sm:px-4 py-4 rounded-md bg-white ring-1 ring-gray-100">
              <div className="text-sm text-gray-500">
                <FaSun />
              </div>
              <p className="text-[11px] text-gray-500 font-light mt-4">
                <FormattedMessage id="powered_by_animation_covered_solar" />
              </p>
              <p className="text-xs text-gray-500 font-medium mt-4">
                1.8 GWh{" "}
                <span className="bg-[#DFFADE] text-[#57A167] rounded-md px-[1px] py-[1px] ml-2 font-regular">
                  +2.4%
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col opacity-0 absolute top-8 horisontal-carousel-step-2 animation-trigger-2 px-4 py-4">
          <div className="mt-2 sm:mt-4 flex justify-between">
            <p className="text-xs text-gray-500">
              <FormattedMessage id="powered_by_animation_live_sustainability" />
            </p>
            <div className="pl-2 pr-1 py-1 ring-1 ring-gray-100 text-xs rounded-md grid grid-cols-4 gap-x-1">
              <p className="text-blueish-400">1d</p> <p>1w</p> <p>1m</p>{" "}
              <p>1y</p>
            </div>
          </div>
          <div className="w-full mt-4 pt-4 sm:pt-8 pb-2 sm:pb-4 px-2 rounded-md bg-white ring-1 ring-gray-100">
            <StaticImage
              src={"../../images/animations/graph_sust.png"}
              placeholder="blurred"
              layout="constrained"
              loading="lazy"
              alt="graph of sustainability data"
            />
          </div>
          <div className="mt-4 sm:mt-8 flex justify-between">
            <p className="text-xs text-gray-500">
              {" "}
              <FormattedMessage id="powered_by_animation_period" />
            </p>
            <p className="text-xs text-gray-500 font-light">
              <FormattedMessage id="powered_by_animation_options" />
              <FaChevronDown className="inline text-[10px]" />
            </p>
          </div>
          <div className="w-full mt-4 grid grid-cols-2 gap-x-4">
            <div className="w-full flex flex-col px-2 sm:px-4 py-4 rounded-md bg-white ring-1 ring-gray-100">
              <div className="text-sm text-gray-500">
                <FaPlus />
              </div>
              <p className="text-[11px] text-gray-500 font-light mt-4">
                <FormattedMessage id="powered_by_animation_grid_addition" />
              </p>
              <p className="text-xs text-gray-500 font-medium mt-4">
                12.7 GWh{" "}
                <span className="bg-[#DFFADE] text-[#57A167] rounded-md px-[1px] py-[1px] ml-2 font-regular">
                  +21.3%
                </span>
              </p>
            </div>
            <div className="w-full lex flex-col px-2 sm:px-4 py-4 rounded-md bg-white ring-1 ring-gray-100">
              <div className="text-sm text-gray-500">
                <FaLongArrowAltDown className="transform -rotate-45" />
              </div>
              <p className="text-[11px] text-gray-500 font-light mt-4">
                <FormattedMessage id="powered_by_animation_emissions_reduction" />
              </p>
              <p className="text-xs text-gray-500 font-medium mt-4">
                20t CO<sub>2</sub>e
                <span className="bg-[#DFFADE] text-[#57A167] rounded-md px-[1px] py-[1px] ml-2 font-regular">
                  +1.7%
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center text-xs text-gray-400 mt-8 tab-indicator animation-trigger-2">
        <div className="text-center tab-1">
          <hr className="w-12 mx-auto border-t-[2px] border-blueish-200 mb-2" />
          <FormattedMessage id="powered_by_animation_consumption" />
        </div>
        <div className="mx-4 text-center tab-2">
          <hr className="w-12 mx-auto border-t-[2px] border-gray-200 mb-2" />
          <FormattedMessage id="powered_by_animation_sustainability" />
        </div>
      </div>
    </div>
  );
};

export default PoweredByRenewables;
