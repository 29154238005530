import React, { useState } from "react";

import ReactModal from 'react-modal';

import { FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { FaBolt } from "@react-icons/all-files/fa/FaBolt";

import ProjectProgressAnimationTwo from "../animations/projectProgressV2";
import PoweredByRenewablesTwo from "../animations/poweredByRenewablesV2";
import OfferForm from "../offer/form";

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenModal() {
    setIsModalOpen(true)
  }

  function handleCloseModal() {
    setIsModalOpen(false)
  }

  return (
    <section className="index-hero">
      <div className="w-screen pt-28 md:pt-40 sm:pb-32 md:pb-0 bg-[#FAFAFA] bg-cover">
        <div className="flex justify-center w-screen pb-8 items-center ">
          <div className="grid grid-cols-1 sm:grid-cols-2 w-full lg:w-1220">
            <div className="flex flex-col px-4 md:pt-6">
              <h1
                className="text-6xl sm:text-7xl md:text-[6rem] lg:text-[6rem] animated-gradient-text-bg font-semibold tracking-tighter pb-8"
                id="hero-heading"
              >
                <FormattedMessage id="hero_heading" />
              </h1>
              <h2
                className="text-base font-light text-gray-700 pb-8 md:pr-12"
                style={{ lineHeight: 1.8 }}
              >
                <FormattedMessage id="hero_sub_heading" />
              </h2>
              <div className="min-h-[150px]">
                <div className="w-full ">

                  <button
                    onClick={handleOpenModal}
                    type="button"
                    className="btn-purple-solid font-regular h-14 px-0 py-0 w-48 text-lg p-6"
                  >
                    <FormattedMessage id="get_offer" />
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden sm:flex justify-center relative pt-8">
              <div className="z-30 absolute top-8 left-[150px]">
                <ProjectProgressAnimationTwo></ProjectProgressAnimationTwo>
              </div>
              <div className="absolute z-20 -top-8 left-[300px]">
                <PoweredByRenewablesTwo></PoweredByRenewablesTwo>
              </div>
            </div>
          </div>
          <ReactModal
            isOpen={isModalOpen}
            contentLabel="Form modal"
            onRequestClose={handleCloseModal}
            className="modal z-50"
            overlayClassName="Overlay"
          >
            <div className="-mr-2 lg:-mr-0.5">
              <div className="relative z-50 w-full h-8 flex justify-end px-4">
                <button className="text-white text-2xl " onClick={handleCloseModal}>x</button>
              </div>
              <div className="w-full -mt-10 lg:-mt-20 z-40 relative">
                <OfferForm ></OfferForm>
              </div>
            </div>
          </ReactModal>
        </div>
      </div>



    </section >
  );
};

export default Hero;
