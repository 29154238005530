import React, { useRef, useEffect } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";

import IntersectionWatcher from "../helpers/intersectionWatcher";

import { FaCircleNotch } from "@react-icons/all-files/fa/FaCircleNotch";

const GreenAnimation = () => {
  const intersectionWatcher = useRef();

  useEffect(() => {
    intersectionWatcher.current = new IntersectionWatcher(0.7, () => {
      const elements = document.getElementsByClassName("animation-trigger-1");
      for (let e of elements) {
        e.classList.add("animation-active");
      }
    });
    intersectionWatcher.current.observe("green-animation");
  }, []);

  return (
    <div className="w-full relative mt-16 sm:mt-0">
      <div
        className="bg-blueish-100 rounded-md h-full overflow-hidden relative px-2 lg:px-16 pt-4 lg:pt-8 pb-2 lg:pb-16"
        id="green-animation"
      >
        <p
          className="text-xl font-bold text-center mt-4 text-gray-700 opacity-0 animation-trigger-1"
          id="green-title"
        >
          <FormattedMessage id="green_animation_title" />
        </p>
        <table
          className="w-full bg-white rounded-md overflow-hidden mt-8 md:mt-14 opacity-0 animation-trigger-1"
          id="green-table"
        >
          <thead className="">
            <tr className="text-gray-700 text-xs text-center bg-[#E6E0FF]">
              <th className="py-4 font-medium">
                <FormattedMessage id="green_animation_expect" />
              </th>
              <th className="py-4 font-medium">
                <FormattedMessage id="green_animation_get" />
              </th>
            </tr>
          </thead>
          <tbody className="text-xs md:text-xs font-light text-gray-700">
            <tr className="">
              <td className="pt-8 pb-4 px-2 text-left">
                <div className="w-full flex px-2">
                  <div className="text-base sm:text-lg text-[#7bed9f] green-icon opacity-50">
                    <FaCircleNotch />
                  </div>
                  <p className="text-xs font-light text-gray-700 ml-4">
                    <FormattedMessage id="green_animation_get_electricity" />
                  </p>
                </div>
              </td>
              <td className="pt-8 pb-4 px-2 text-left">
                <div className="w-full flex px-2">
                  <div className="text-base sm:text-lg text-[#ced6e0] green-icon opacity-50">
                    <FaCircleNotch />
                  </div>
                  <p className="text-xs font-light text-gray-700 ml-4">
                    <FormattedMessage id="green_animation_expect_electricity" />
                  </p>
                </div>
              </td>
            </tr>
            <tr className="">
              <td className="py-4 px-2 text-left">
                <div className="w-full flex px-2">
                  <div className="text-base sm:text-lg text-[#7bed9f] green-icon opacity-50">
                    <FaCircleNotch />
                  </div>
                  <p className="text-xs font-light text-gray-700 ml-4">
                    <FormattedMessage id="green_animation_get_reductions" />
                  </p>
                </div>
              </td>
              <td className="py-4 px-2 text-left">
                <div className="w-full flex px-2">
                  <div className="text-base sm:text-lg text-[#ced6e0] green-icon opacity-50">
                    <FaCircleNotch />
                  </div>
                  <p className="text-xs font-light text-gray-700 ml-4">
                    <FormattedMessage id="green_animation_expect_reductions" />
                  </p>
                </div>
              </td>
            </tr>
            <tr className="">
              <td className="pt-4 pb-8 px-2 text-left">
                <div className="w-full flex px-2">
                  <div className="text-base sm:text-lg text-[#7bed9f] green-icon opacity-50">
                    <FaCircleNotch />
                  </div>
                  <p className="text-xs font-light text-gray-700 ml-4">
                    <FormattedMessage id="green_animation_get_branding" />
                  </p>
                </div>
              </td>
              <td className="pt-4 pb-8 px-2 text-left">
                <div className="w-full flex px-2">
                  <div className="text-base sm:text-lg text-[#ced6e0] green-icon opacity-50">
                    <FaCircleNotch />
                  </div>
                  <p className="text-xs font-light text-gray-700 ml-4">
                    <FormattedMessage id="green_animation_expect_branding" />
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GreenAnimation;
