import React, { useState, useEffect, useRef } from "react";

import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl";

import IntersectionWatcher from "../helpers/intersectionWatcher";
import LocaleData from "../helpers/localeData";

const ImpactCalculator = () => {
  const intl = useIntl();

  // Slider
  const minSliderValue = 0;
  const maxSliderValue = 1000;
  const firstTickPosition = 70;
  const [activePip, setActivePip] = useState(-1);

  // Initial state
  const [sliderValue, setSliderValue] = useState(0);
  const [consumption, setConsumption] = useState(0); // MWh
  const [impact, setImpact] = useState({
    emissionsSavingsAbs: 0,
    numSolarParks: 0,
    citizensEmissionsEquivalent: 0,
  });

  // Wathcer for element intersection
  const intersectionWatcher = useRef();

  // Called on mount
  useEffect(() => {
    intersectionWatcher.current = new IntersectionWatcher(0.7, () => {
      animateStartValue(consumption, setConsumption);
    });
    intersectionWatcher.current.observe("impact-calcuator");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Called every time 'consumption' state is updated
  useEffect(() => {
    updateActivePip();
    calculateImpact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumption]);

  // Called every time 'slider value' state is updated
  useEffect(() => {
    // Update consumption with conversion
    setConsumption(sliderValueToConsumption(sliderValue));
  }, [sliderValue]);

  // Emissions data
  const gridMixEmissionIntensityDK = 122; // kg CO2e/MWh
  const solarEmissionIntensityDK = 6; // kg CO2e/MWh

  const danishCitizenAverageEmissions = 5.8; //metric tonnes CO2e / year

  // Assumptions
  const solarParkSize = 30000; // MWh/year

  // Impact calculation
  function calculateImpact() {
    const consumerEmissionYearlyGridMix =
      consumption * gridMixEmissionIntensityDK;

    const consumerEmissionsYearlyPPA = consumption * solarEmissionIntensityDK;

    let emissionsSavingsAbs =
      consumerEmissionYearlyGridMix - consumerEmissionsYearlyPPA;
    emissionsSavingsAbs = emissionsSavingsAbs / 1000; // from kg to tonnes
    emissionsSavingsAbs = Math.round(emissionsSavingsAbs);

    setImpact({
      emissionsSavingsAbs: emissionsSavingsAbs,
      numSolarParks: Math.round((consumption / solarParkSize) * 100) / 100,
      citizensEmissionsEquivalent: Math.round(
        emissionsSavingsAbs / danishCitizenAverageEmissions
      ),
    });
  }

  // Map linear slider values to stepped consumption values
  function sliderValueToConsumption(updatedSliderValue) {
    if (updatedSliderValue < 300) {
      return updatedSliderValue * 2;
    } else if (updatedSliderValue < 600) {
      return updatedSliderValue * 4;
    } else if (updatedSliderValue < 770) {
      return updatedSliderValue * 13.5;
    } else if (updatedSliderValue < 820) {
      return updatedSliderValue * 30;
    } else if (updatedSliderValue < 870) {
      return updatedSliderValue * 40;
    } else if (updatedSliderValue < 930) {
      return updatedSliderValue * 60;
    } else if (updatedSliderValue < 990) {
      return updatedSliderValue * 82;
    }
    return updatedSliderValue * 100;
  }

  // Calculate which pips should be marked as active
  function updateActivePip() {
    let activePip = Math.floor(
      ((sliderValue + firstTickPosition) / maxSliderValue) * 9
    );
    setActivePip(activePip);
  }

  function updateSliderValue(event) {
    let val = parseInt(event.target.value);
    setSliderValue(val);
  }

  // Animate start value of slider
  function animateStartValue() {
    const startValue = 0;
    const endValue = 400;
    const range = endValue - startValue;

    const duration = 400;
    const numSteps = 20;
    const increment = Math.floor(range / numSteps);
    const timeStep = Math.floor(duration / numSteps);

    animateStartValueRecurse(startValue, endValue, increment, timeStep);
  }

  function animateStartValueRecurse(current, endValue, increment, timestep) {
    if (current < endValue) {
      let next = current + increment;
      setSliderValue(next);
      setTimeout(() => {
        animateStartValueRecurse(next, endValue, increment, timestep);
      }, timestep);
    }
  }

  return (
    <section className="index-impact-calculator">
      <div className="w-screen bg-[#FDFDFD]">
        <div className="flex justify-center w-screen pb-4 md:pb-6 pt-12 lg:px-6">
          <div className="w-full lg:max-w-1080 grid grid-cols-1 lg:grid-cols-2 gap-4 bg-blueish-100 lg:rounded-md px-4 py-6 lg:py-20">
            <div className="w-full flex justify-center lg:justify-end lg:pr-10 order-2 lg:order-1 lg:pt-0">
              <div
                className="flex flex-col w-[320px] sm:w-[380px] bg-[#37373B] px-4 md:px-8 py-6 rounded-md ring-8 ring-[#434347]"
                id="impact-calcuator"
              >
                <h2 className="text-xl font-medium text-white">
                  <FormattedMessage id="impact_calculator_title" />
                </h2>
                <div className="flex flex-col pb-8 align-center justify-center text-white w-full">
                  <div className="mt-6 flex w-full justify-between items-center">
                    <p className="text-sm mr-4">
                      <FormattedMessage id="impact_calculator_consumption" />
                    </p>
                    <div className="text-sm px-2 py-2 bg-[#434347] rounded-md text-center">
                      <div className="w-14 inline-block text-right">
                        <span>
                          {consumption.toLocaleString(
                            LocaleData(intl.locale)["code"]
                          )}
                        </span>
                      </div>{" "}
                      MWh
                    </div>
                  </div>
                  <div className="px-1">
                    <div className="flex justify-between pb-2 mt-10">
                      <div className="text-xs font-medium">
                        0 <span className="font-light">MWh</span>
                      </div>
                      <div className="text-xs font-medium">
                        <span>
                          {parseInt("100000").toLocaleString(
                            LocaleData(intl.locale)["code"]
                          )}
                        </span>

                        <span className="font-light">MWh</span>
                      </div>
                    </div>
                    <div className="slidecontainer">
                      <input
                        type="range"
                        min={minSliderValue}
                        max={maxSliderValue}
                        value={sliderValue}
                        onChange={updateSliderValue}
                        step="1"
                        className="slider"
                        id="myRange"
                        list="tickmarks"
                      />

                      <div id="tickmarks">
                        <div className="flex flex-col items-center relative">
                          <p
                            style={{
                              background:
                                activePip >= 1 ? "#7553ff" : "#4D4D52",
                            }}
                            className="transition-background duration-300 ease-in-out"
                          ></p>

                          <div className="text-xs w-12 text-center mt-2">
                            <FormattedMessage id="impact_calculator_medium_office" />
                          </div>
                        </div>
                        <p
                          style={{
                            background: activePip >= 2 ? "#7553ff" : "#4D4D52",
                          }}
                          className="transition-background duration-300 ease-in-out"
                        ></p>
                        <div className="flex flex-col items-center relative">
                          <p
                            style={{
                              background:
                                activePip >= 3 ? "#7553ff" : "#4D4D52",
                            }}
                            className="transition-background duration-300 ease-in-out"
                          ></p>

                          <div className="text-xs w-12 text-center mt-2">
                            <FormattedMessage id="impact_calculator_large_office" />
                          </div>
                        </div>
                        <p
                          style={{
                            background: activePip >= 4 ? "#7553ff" : "#4D4D52",
                          }}
                          className="transition-background duration-300 ease-in-out"
                        ></p>
                        <div className="flex flex-col items-center relative">
                          <p
                            style={{
                              background:
                                activePip >= 5 ? "#7553ff" : "#4D4D52",
                            }}
                            className="transition-background duration-300 ease-in-out"
                          ></p>

                          <div className="text-xs w-12 text-center mt-2">
                            <FormattedMessage id="impact_calculator_small_factory" />
                          </div>
                        </div>
                        <p
                          style={{
                            background: activePip >= 6 ? "#7553ff" : "#4D4D52",
                          }}
                          className="transition-background duration-300 ease-in-out"
                        ></p>
                        <div className="flex flex-col items-center relative">
                          <p
                            style={{
                              background:
                                activePip >= 7 ? "#7553ff" : "#4D4D52",
                            }}
                            className="transition-background duration-300 ease-in-out"
                          ></p>

                          <div className="text-xs w-12 text-center mt-2">
                            <FormattedMessage id="impact_calculator_medium_factory" />
                          </div>
                        </div>
                        <p
                          style={{
                            background: activePip >= 8 ? "#7553ff" : "#4D4D52",
                          }}
                          className="transition-background duration-300 ease-in-out"
                        ></p>
                        <div className="flex flex-col items-center relative">
                          <p
                            style={{
                              background:
                                activePip >= 9 ? "#7553ff" : "#4D4D52",
                            }}
                            className="transition-background duration-300 ease-in-out"
                          ></p>

                          <div className="text-xs w-12 text-center mt-2">
                            <FormattedMessage id="impact_calculator_large_factory" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="border border-[#434347] w-40 mt-2 mx-auto" />
                <div className="flex pb-2 items-center mt-6 text-white">
                  <div className=" font-regular text-sm sm:text-sm ml-2 flex flex-row items-center">
                    <span>
                      <FormattedMessage id="impact_calculator_your_impact" />
                    </span>
                  </div>
                </div>
                <div className="flex flex-row text-white">
                  {/* <vr className="h-22 border border-black border-dashed ml-3" /> */}
                  <div className="grid grid-cols-2 gap-3 text-center mt-6 pb-2">
                    <div className="flex flex-col items-center ring-2 ring-[#434347] rounded-md px-2 py-2">
                      <div className="px-4 w-full">
                        <div className="text-lg w-full text-center bg-[#434347] text-blueish-400 py-[2px] rounded-md">
                          <span>
                            {impact.emissionsSavingsAbs.toLocaleString(
                              LocaleData(intl.locale)["code"]
                            )}
                          </span>
                        </div>
                      </div>

                      <div className=" text-xs  mt-2">
                        <FormattedMessage id="impact_calculator_tonnes" /> CO
                        <sub>2</sub>e{" "}
                        <FormattedMessage id="impact_calculator_saved" />
                      </div>
                    </div>

                    <div className="flex flex-col items-center ring-2 ring-[#434347] rounded-md px-2 py-2">
                      <div className="px-4 w-full">
                        <div className="text-lg w-full text-center bg-[#434347] text-blueish-400 py-[2px] rounded-md">
                          {impact.citizensEmissionsEquivalent}
                        </div>
                      </div>

                      <div className="text-xs  mt-2">
                        <FormattedMessage id="impact_calculator_citizens_eq" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col order-1 lg:order-2 pb-8 lg:pb-0 lg:pl-8">
              <div className="font-medium text-base text-blueish-400 py-4">
                <FormattedMessage id="impact_calculator_tag" />
              </div>
              <h2 className="text-2xl md:text-4xl font-bold lg:text-5xl text-left text-gray-700 max-w-lg py-2">
                <FormattedMessage id="impact_calculator_heading" />
              </h2>
              <div className="lg:mt-6">
                <h3 className="text-base max-w-[420px] text-[#9797A1] py-4">
                  <FormattedMessage id="impact_calculator_paragraph" />
                  <span className="underline block mt-4">
                    {" "}
                    <Link to="/impact-calculations">
                      <FormattedMessage id="impact_calculator_how_we_calculate_link" />
                    </Link>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImpactCalculator;
