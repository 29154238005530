import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";

import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";

const SignUpAnimation = () => {
  return (
    <div className="w-full h-full">
      <div className="shadow-3xl bg-white rounded-md h-full overflow-hidden relative">
        <div className="w-full flex flex-col opacity-0 absolute top-0 px-4 py-6 sm:px-12 sm:py-10 horisontal-carousel-step-1 animation-trigger-2">
          <p className="text-gray-700 text-xs font-medium mt-4 sm:mt-6">
            <FormattedMessage id="signup_animation_name_title" />
          </p>
          <div className="w-full mt-2 ring-1 ring-gray-200 flex rounded-md text-gray-700 justify-between px-2 py-2 sm:px-4 sm:py-4 text-xs">
            <p>
              <FormattedMessage id="signup_animation_name_text" />
            </p>{" "}
            <span className="text-[#10ac84] opacity-60">
              <FaCheckCircle />
            </span>
          </div>
          <p className="text-gray-700 text-xs font-medium mt-4 sm:mt-6">
            <FormattedMessage id="signup_animation_email_title" />
          </p>
          <div className="w-full mt-2 ring-1 ring-gray-200 flex rounded-md text-gray-700 justify-between px-2 py-2 sm:px-4 sm:py-4 text-xs">
            <p>
              <FormattedMessage id="signup_animation_email_text" />
            </p>
            <span className="text-[#10ac84] opacity-60">
              <FaCheckCircle />
            </span>
          </div>
          <p className="text-gray-700 text-xs font-medium mt-4 sm:mt-6">
            <FormattedMessage id="signup_animation_company_title" />
          </p>
          <div className="w-full mt-2 ring-1 ring-gray-200 flex rounded-md text-gray-700 justify-between px-2 py-2 sm:px-4 sm:py-4 text-xs">
            <p>
              <FormattedMessage id="signup_animation_company_text" />
            </p>
            <span className="text-[#10ac84] opacity-60">
              <FaCheckCircle />
            </span>
          </div>
          <div className="h-10 w-full text-center text-sm px-2 pt-2 bg-blueish-400 text-white rounded-md mt-12 sm:mt-20">
            <FormattedMessage id="signup_animation_next" />
          </div>
        </div>
        <div className="w-full flex flex-col opacity-0 absolute top-0 px-4 py-6 sm:px-12 sm:py-16 horisontal-carousel-step-2 animation-trigger-2">
          <p className="text-gray-700 text-xs font-medium">
            <FormattedMessage id="progress_signup_company_size" />
          </p>
          <div className="w-full grid grid-cols-3 gap-x-2 sm:gap-x-4 mt-2">
            <div className="ring-1 ring-gray-400 rounded-md flex flex-col justify-center h-20 text-center">
              <div className="text-sm w-full flex justify-center mb-2">
                <FaBuilding />
              </div>
              <p className="text-xs font-regular text-gray-700">1-50</p>
              <p className="text-xs font-light text-gray-700">
                <FormattedMessage id="progress_signup_people" />
              </p>
            </div>
            <div className="ring-1 ring-gray-200 rounded-md flex flex-col justify-center h-20 text-center">
              {" "}
              <div className="text-base w-full flex justify-center mb-2 text-gray-500">
                <FaBuilding />
              </div>
              <p className="text-xs font-regular text-gray-500">51-200</p>
              <p className="text-xs font-light text-gray-500">
                <FormattedMessage id="progress_signup_people" />
              </p>
            </div>
            <div className="ring-1 ring-gray-200 rounded-md flex flex-col justify-center h-20 text-center">
              {" "}
              <div className="text-lg w-full flex justify-center mb-2 text-gray-500">
                <FaBuilding />
              </div>
              <p className="text-xs font-regular text-gray-500">> 200</p>
              <p className="text-xs font-light text-gray-500">
                <FormattedMessage id="progress_signup_people" />
              </p>
            </div>
          </div>
          <p className="text-gray-700 text-xs font-medium mt-4 sm:mt-6">
            <FormattedMessage id="progress_signup_industry_title" />
          </p>
          <div className="w-full mt-2 ring-1 ring-gray-200 flex rounded-md text-gray-700 justify-between px-2 py-2 sm:px-4 sm:py-4 text-xs">
            <p>
              <FormattedMessage id="progress_signup_industry_text" />
            </p>{" "}
            <FaChevronDown className="mt-1" />
          </div>
          <p className="text-gray-700 text-xs font-medium mt-4 sm:mt-6">
            <FormattedMessage id="progress_signup_consumption_title" />
          </p>
          <div className="w-full mt-2 ring-1 ring-gray-200 flex rounded-md text-gray-700 justify-between px-2 py-2 sm:px-4 sm:py-4 text-xs">
            <p> 2200</p>
          </div>
          <div className="h-10 w-full text-center text-sm px-2 pt-2 bg-blueish-400 text-white rounded-md mt-4 sm:mt-12">
            <FormattedMessage id="signup_animation_next" />
          </div>
        </div>
      </div>
      <div className="flex justify-center text-xs text-gray-400 mt-8 tab-indicator animation-trigger-2">
        <div className="text-center tab-1">
          <hr className="w-12 mx-auto border-t-[2px] border-gray-200 mb-2" />
          <FormattedMessage id="signup_animation_contact" />
        </div>
        <div className="mx-4 text-center tab-2">
          <hr className="w-12 mx-auto border-t-[2px] border-gray-200 mb-2" />
          <FormattedMessage id="signup_animation_consumption" />
        </div>
      </div>
    </div>
  );
};

export default SignUpAnimation;
