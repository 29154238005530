import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import Hero from "../components/index/hero";
import HowItWorks from "../components/index/howItWorks";
import ImpactCalculator from "../components/index/impactCalculator";
import IndexComparisonTable from "../components/index/table";
import FeaturedIn from "../components/offer/featuredIn";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Reel - Electricity for a green future" />
      <Hero></Hero>
      <FeaturedIn bgClasses="bg-[#FAFAFA]"></FeaturedIn>
      <HowItWorks></HowItWorks>
      <ImpactCalculator></ImpactCalculator>
      <IndexComparisonTable></IndexComparisonTable>
    </Layout>
  );
};
export default IndexPage;
