import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { FaTimesCircle } from "@react-icons/all-files/fa/FaTimesCircle";

const IndexComparisonTable = () => {
  return (
    <section className="index-table">
      <div className="w-screen relative">
        <div className="flex flex-col justify-center items-center w-screen pt-12 lg:pt-28 pb-16 lg:pb-40 bg-[#FDFDFD] px-4 lg:px-0">
          <div className="w-full lg:w-1000">
            <div className="font-regular text-base py-4 text-blueish-400">
              <FormattedMessage id="comparison_table_tag" />
            </div>
            <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold py-2 text-gray-700 max-w-xl">
              <FormattedMessage id="comparison_table_heading" />
            </h2>

            <h3 className="text-base lg:text-left w-full md:max-w-[500px] text-[#9797A1] py-4">
              <FormattedMessage id="comparison_table_paragraph" />
            </h3>
          </div>
          <div className="w-full lg:w-1000 bg-gray-800 rounded-md flex flex-col justify-center items-center h-full py-10 md:py-10 z-20 mt-12 shadow">
            <div className="mt-4 px-4 lg:px-12 flex flex-col-reverse md:flex-row justify-between w-full items-start md:items-center">
              <div className="w-full">
                <div className="overflow-x-auto">
                  <table className="min-w-max w-full table-auto text-xs">
                    <thead>
                      <tr className="">
                        <th
                          className="py-5 sm:px-6"
                          aria-label="comparison table categories"
                        ></th>
                        <th className="py-5 sm:px-6">
                          <span className="text-white font-regular text-xs md:text-sm bg-gray-700 px-1 sm:px-2 py-1 sm:py-2 rounded-md">
                            <FormattedMessage id="comparison_table_traditoinal_utility" />
                          </span>
                        </th>
                        <th className="py-5 sm:px-6">
                          <div className="h-1 sm:h-[10px] mx-auto">
                            <StaticImage
                              src={"../../images/reel/reel_logo_white.png"}
                              placeholder="blurred"
                              height={10}
                              layout="constrained"
                              loading="lazy"
                              alt="reel logo"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-xs md:text-xs font-light text-white">
                      <tr className="border-b border-gray-700 ">
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center">
                            <span className="text-xs sm:text-sm font-regular w-10 sm:w-full">
                              <FormattedMessage id="comparison_table_security" />
                            </span>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#2ecc71]">
                              <FaCheckCircle />
                            </div>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-center">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#2ecc71]">
                              <FaCheckCircle />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center">
                            <span className="text-xs sm:text-sm font-regular w-10 sm:w-full">
                              <FormattedMessage id="comparison_table_pricing" />
                            </span>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#2ecc71]">
                              <FaCheckCircle />
                            </div>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-center">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#2ecc71]">
                              <FaCheckCircle />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-t border-b border-gray-700 ">
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center">
                            <span className="text-xs sm:text-sm font-regular w-10 sm:w-full">
                              <FormattedMessage id="comparison_table_certificates" />
                            </span>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#2ecc71]">
                              <FaCheckCircle />
                            </div>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-center">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#2ecc71]">
                              <FaCheckCircle />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center">
                            <span className="text-xs sm:text-sm font-regular w-10 sm:w-full">
                              <FormattedMessage id="comparison_table_additional" />
                            </span>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#e74c3c]">
                              <FaTimesCircle />
                            </div>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-center">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#2ecc71]">
                              <FaCheckCircle />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-t border-b border-gray-700 ">
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center">
                            <span className="text-xs sm:text-sm font-regular w-10 sm:w-full">
                              <FormattedMessage id="comparison_table_credited" />
                            </span>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#e74c3c]">
                              <FaTimesCircle />
                            </div>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-center">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#2ecc71]">
                              <FaCheckCircle />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center">
                            <span className="text-xs sm:text-sm font-regular w-10 sm:w-full">
                              <FormattedMessage id="comparison_table_reporting" />
                            </span>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-left">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#e74c3c]">
                              <FaTimesCircle />
                            </div>
                          </div>
                        </td>
                        <td className="py-5 md:px-6 text-center">
                          <div className="flex items-center justify-center">
                            <div className="text-base sm:text-lg text-[#2ecc71]">
                              <FaCheckCircle />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndexComparisonTable;
