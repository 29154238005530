import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

const ProjectProgressAnimation = () => {
  return (
    <div className="h-[350px] w-[280px] sm:w-[370px] sm:h-[440px] relative">
      <div className="bg-white px-3 py-3 shadow-3xl rounded-md z-10">
        <div className="w-full grid grid-cols-1 progress-images animation-trigger-2">
          <StaticImage
            src={"../../images/animations/progress_1.png"}
            alt="Empty field"
            placeholder="blurred"
            layout="constrained"
            loading="eager"
            className="rounded-md row-start-1 row-end-1 col-start-1 col-end-1 z-10"
          />
          <StaticImage
            src={"../../images/animations/progress_2.png"}
            alt="Field with planned solar cells outline"
            placeholder="blurred"
            layout="constrained"
            loading="eager"
            className="rounded-md row-start-1 row-end-1 col-start-1 col-end-1 opacity-0 z-20"
          />
          <StaticImage
            src={"../../images/animations/progress_3.png"}
            alt="Fields with finished solar cells"
            placeholder="blurred"
            layout="constrained"
            loading="eager"
            className="rounded-md row-start-1 row-end-1 col-start-1 col-end-1 opacity-0 z-30"
          />
        </div>
        <div className="pb-4 pt-8">
          <ol className="progress animation-trigger-2 text-xs">
            <li>
              <div className="max-w-[70px] text-center mx-auto">
                <FormattedMessage id="progress_animation_contract" />
              </div>
            </li>
            <li>
              <div className="max-w-[70px] text-center mx-auto">
                <FormattedMessage id="progress_animation_construction" />
              </div>
            </li>
            <li className="progress-last">
              <div className="max-w-[70px] text-center mx-auto">
                <FormattedMessage id="progress_animation_finished" />
              </div>
            </li>
          </ol>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default ProjectProgressAnimation;
