import React, { useEffect, useRef } from "react";

import { FormattedMessage, Link } from "gatsby-plugin-react-intl";

import IntersectionWatcher from "../helpers/intersectionWatcher";

import GreenAnimation from "../animations/green";
import SignUpAnimation from "../animations/signup";
import ProjectSelectionAnimation from "../animations/projectSelection";
import ProjectProgressAnimation from "../animations/projectProgress";
import PoweredByRenewables from "../animations/poweredByRenewables";

const HowItWorks = () => {
  const intersectionWatcher = useRef();

  useEffect(() => {
    // If screen is sm or smaller, start animation
    if (window.screen.width < 640) {
      const elements = document.getElementsByClassName("animation-trigger-2");
      for (let e of elements) {
        e.classList.add("animation-active");
      }
    }
    // Otherwise use intersectionwatcher to start animation on intersection
    else {
      intersectionWatcher.current = new IntersectionWatcher(0.7, () => {
        const elements = document.getElementsByClassName("animation-trigger-2");
        for (let e of elements) {
          e.classList.add("animation-active");
        }
      });
      intersectionWatcher.current.observe("hiw-carousel");
    }
  }, []);

  function carouselHandleClick(childIndex) {
    const activeCarouselLabel = "carousel-label-" + childIndex;
    let labelElements = document.querySelectorAll("[class*='carousel-label-']");
    toggleElementsActiveState(labelElements, activeCarouselLabel);

    const activeCarouselChild = "carousel-child-" + childIndex;
    let childElements = document.querySelectorAll("[class*='carousel-child-']");
    toggleElementsActiveState(childElements, activeCarouselChild);
  }

  function toggleElementsActiveState(elements, activeElementLabel) {
    elements.forEach((e) => {
      // Pause looping animation
      e.classList.remove("animation-active");
      // Mark element at index as selected
      if (e.classList.contains(activeElementLabel)) {
        e.classList.add("active");
      }
      // The inactive indicies
      else {
        if (e.classList.contains("active")) {
          e.classList.remove("active");
        }
      }
    });
  }

  return (
    <section className="index-how-it-works">
      <div className="flex justify-center w-screen px-4 pt-12 lg:pt-20 bg-[#FDFDFD]">
        <div className="w-full lg:w-1100 border-b-2 border-[#F0F0FA]">
          <div className="flex flex-col items-start w-full relative ">
            <div className="flex flex-col w-full px-4 md:px-16 lg:px-16">
              <div className="font-medium text-base text-blueish-400 py-4">
                <FormattedMessage id="problem_tag" />
              </div>
              <h2 className="text-2xl md:text-4xl font-bold lg:text-5xl text-gray-700 max-w-lg py-2">
                <FormattedMessage id="problem_heading" />
              </h2>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 justify-between mt-8 md:mt-16 relative w-full border-r-2 border-[#F0F0FA] px-4 md:px-16 lg:px-16 pb-12 sm:pb-16">
              <div className="flex flex-col items-end">
                <div className="relative w-full">
                  <div className="font-medium text-sm py-4 text-gray-700 pt-1 md:max-w-[350px] lg:max-w-[400px]">
                    <FormattedMessage id="problem_title" />
                  </div>
                  <div className="font-light text-sm text-[#9797A1]  md:max-w-[350px] lg:max-w-[400px] pr-6">
                    <FormattedMessage id="problem_paragraph" />
                    <span className="underline block mt-4 hover:text-gray-500">
                      <Link to="/sustainability">
                        <FormattedMessage id="problem_learn_more_link" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-[540px] lg:-ml-12 mx-auto">
                <GreenAnimation></GreenAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center w-screen px-4 pb-28 md:pb-32 bg-[#FDFDFD]">
        <div className="w-full lg:w-1100 border-l-2 border-[#F0F0FA] px-4 md:pl-16 md:pr-0 lg:pl-16 pt-8 lg:pt-20">
          <div className="flex flex-col">
            <div className="flex flex-col w-full">
              <div className="font-medium text-base py-4 text-blueish-400">
                <FormattedMessage id="hiw_tag" />
              </div>
              <h2 className="text-2xl md:text-4xl font-bold lg:text-5xl py-2 text-gray-700 max-w-2xl">
                <FormattedMessage id="hiw_heading" />
              </h2>
            </div>

            <div
              className="w-full grid grid-cols-1 sm:grid-cols-2 mt-8 md:mt-20 vertical-single-carousel"
              id="hiw-carousel"
            >
              <div
                className="flex flex-col items-start mb-6 carousel-label-1 animation-trigger-2 md:cursor-pointer"
                role="button"
                onClick={(e) => {
                  carouselHandleClick(1);
                }}
                onKeyDown={(e) => {
                  carouselHandleClick(1);
                }}
                tabIndex={0}
              >
                <div className="flex flex-col max-w-[350px] lg:max-w-[400px] pr-6">
                  <div className="w-8 h-8 flex justify-center items-center rounded-md carousel-index mt-4">
                    1
                  </div>
                  <div className="font-medium text-sm pb-1 text-gray-700 mt-4 relative">
                    <div className="h-[16px] w-[2px] absolute left-[-18px] md:left-[-66px] top-[2px] line-before"></div>
                    <FormattedMessage id="hiw_signup_title" />
                  </div>
                  <div className="font-light text-sm text-[#9797A1] ">
                    <FormattedMessage id="hiw_signup_text" />
                  </div>
                </div>
              </div>

              <div className="h-[370px] w-[260px] sm:w-[370px] sm:h-[500px] sm:opacity-0 carousel-child-1 animation-trigger-2 sm:row-start-1 sm:row-end-5 sm:col-start-2 sm:col-end-4 mt-12 md:mt-28 mx-auto">
                <SignUpAnimation></SignUpAnimation>
              </div>

              <div
                className="flex flex-col items-start mt-16 sm:mt-0 mb-6 carousel-label-2 animation-trigger-2 md:cursor-pointer"
                role="button"
                onClick={(e) => {
                  carouselHandleClick(2);
                }}
                onKeyDown={(e) => {
                  carouselHandleClick(2);
                }}
                tabIndex={0}
              >
                <div className="flex flex-col max-w-[350px] lg:max-w-[400px] pr-6">
                  <div className="w-8 h-8 flex justify-center items-center rounded-md carousel-index mt-4">
                    2
                  </div>
                  <div className="font-medium text-sm pb-1 text-gray-700 mt-4 relative">
                    <div className="h-[16px] w-[2px] absolute left-[-18px] md:left-[-66px] top-[2px] line-before"></div>
                    <FormattedMessage id="hiw_selection_title" />
                  </div>
                  <div className="font-light text-sm text-[#9797A1] ">
                    <FormattedMessage id="hiw_selection_text" />
                  </div>
                </div>
              </div>

              <div className="w-[400px] h-96 sm:opacity-0 carousel-child-2 animation-trigger-2 md:row-start-1 sm:row-start-1 sm:row-end-5 sm:col-start-2 sm:col-end-4 mt-12 md:mt-28 mx-auto">
                <ProjectSelectionAnimation></ProjectSelectionAnimation>
              </div>

              <div
                className="flex flex-col items-start mt-20 sm:mt-0 mb-6 carousel-label-3 animation-trigger-2 md:cursor-pointer"
                role="button"
                onClick={(e) => {
                  carouselHandleClick(3);
                }}
                onKeyDown={(e) => {
                  carouselHandleClick(3);
                }}
                tabIndex={0}
              >
                <div className="flex flex-col max-w-[350px] lg:max-w-[400px] pr-6">
                  <div className="w-8 h-8 flex justify-center items-center rounded-md carousel-index mt-4">
                    3
                  </div>
                  <div className="font-medium text-sm pb-1 text-gray-700 mt-4 relative">
                    <div className="h-[16px] w-[2px] absolute left-[-18px] md:left-[-66px] top-[2px] line-before"></div>
                    <FormattedMessage id="hiw_build_title" />
                  </div>
                  <div className="font-light text-sm text-[#9797A1] ">
                    <FormattedMessage id="hiw_build_text" />
                  </div>
                </div>
              </div>

              <div className="sm:opacity-0 carousel-child-3 animation-trigger-2 md:row-start-1 sm:row-start-1 sm:row-end-5 sm:col-start-2 sm:col-end-4 mt-8 md:mt-36 lg:mt-28 mx-auto">
                <ProjectProgressAnimation></ProjectProgressAnimation>
              </div>

              <div
                className="flex flex-col items-start mt-32 sm:mt-0 carousel-label-4 animation-trigger-2 md:cursor-pointer"
                role="button"
                onClick={(e) => {
                  carouselHandleClick(4);
                }}
                onKeyDown={(e) => {
                  carouselHandleClick();
                }}
                tabIndex={0}
              >
                <div className="flex flex-col max-w-[350px] lg:max-w-[400px] pr-6">
                  <div className="w-8 h-8 flex justify-center items-center rounded-md carousel-index mt-4">
                    4
                  </div>
                  <div className="font-medium text-sm pb-1 text-gray-700 mt-4 relative">
                    <div className="h-[16px] w-[2px] absolute left-[-18px] md:left-[-66px] top-[2px] line-before"></div>
                    <FormattedMessage id="hiw_electricity_title" />
                  </div>
                  <div className="font-light text-sm text-[#9797A1] ">
                    <FormattedMessage id="hiw_electricity_text" />
                  </div>
                </div>
              </div>

              <div className="sm:opacity-0 carousel-child-4 animation-trigger-2 sm:row-start-1 sm:row-end-5 sm:col-start-2 sm:col-end-4 mt-14 md:mt-36 lg:mt-28 mx-auto">
                <PoweredByRenewables></PoweredByRenewables>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
